import { FC, Dispatch, SetStateAction } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
  selectedDate: MaterialUiPickersDate | null;
  setSelectedDate: Dispatch<SetStateAction<MaterialUiPickersDate>>;
  disabled?: boolean;
  type:
    | 'From'
    | 'To'
    | 'Oldest Timestamp'
    | 'Date of Collection'
    | 'Presence'
    | 'ChatterFrom'
    | 'ChatterTo'
    | 'CreatedFrom';
  color: 'primary' | 'secondary';
}

const label = (type: string) => {
  switch (type) {
    case 'From':
      return `Date ${type} for Export`;
    case 'To':
      return `Date ${type} for Export`;
    case 'Oldest Timestamp':
      return `${type} for Export`;
    case 'Date of Collection':
      return 'Set Date of Collection';
    case 'Presence':
      return 'Presence date (empty is latest)';
    case 'ChatterFrom':
      return `Date from for Scrape`;
    case 'ChatterTo':
      return `Date to for Scrape`;
    case 'CreatedFrom':
      return `Created Date From for Export`;
    default:
      return 'Select a Date';
  }
};

const DateSelector: FC<Props> = ({ selectedDate, setSelectedDate, disabled, type, color }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={selectedDate}
        autoOk
        disableFuture
        disabled={disabled}
        color={color}
        format="yyyy-MM-dd"
        margin="normal"
        id={`date-picker-${type}`}
        label={label(type)}
        variant="inline"
        onChange={setSelectedDate}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateSelector;
